import Store from '@/store'
import Route from '@/routes'


const checkPage = (login) => {
	if ((login && !Store.state.hasLogin) || (!login && Store.state.hasLogin)) {
		backPage();
		return false;
	}
	return true;
}

const backPage = () => {
	window.history.go(-1);
}

const checkAuth = (url, action) => {
	let actions = Store.state.api_actions;
	if (!actions.has(url)) return false;
	if (!actions.get(url).has(action)) return false;
	return true;
}

const h5Copy = (c) => {
	let t = document.createElement("textarea");
	t.value = c;
	t.readOnly = "readOnly";
	document.body.appendChild(t);
	// 选择对象
	t.select();
	// 核心
	t.setSelectionRange(0, c.length);
	// 执行浏览器复制命令
	let r = document.execCommand("Copy");
	t.remove();
	return r;
}

// 二维数组转多维数组，递归加载子类目
const one2Many = (one, two) => {
	if (!one) return one;
	for (var i in one) {
		if (two[one[i].id]) {
			one[i]['children'] = two[one[i].id];
			one2Many(one[i]['children'], two);
		}
	}
	return one;
}

const buildTree = (data) => {
	var parent_id = null;
	// 用哈希表存储每个节点
	const nodes = {};
	for (let node of data) {
		nodes[node.id] = { ...node, children: [] };
	}
	// data.forEach(node => {
	// 	nodes[node.id] = { ...node, children: [] };
	// });

	// 将每个节点添加到其父节点的子节点列表中
	Object.values(nodes).forEach(node => {
		var parent = nodes[node.parent_id];
		if (parent) {
			parent.children.push(node);
		}
		if (parent_id == null || parent_id > node.parent_id) {
			parent_id = node.parent_id;
		}
	});
	Object.values(nodes).map(node => { if (node.children.length === 0) delete node['children'] });
	// 返回顶级节点的列表
	return Object.values(nodes).filter(node => node.parent_id === parent_id);
}

const handleDownload = (header, filter, list, file, sheet) => {
	const { ad_export_json_to_excel } = require('./exportExcel');
	const data = list.map(v => filter.map(j => v[j]))
	ad_export_json_to_excel(header, data, file, sheet);
}

const toRoute = (p) => {
	if (!p) return false;
	Route.push({path: p});
}

const checkStatus = (model, action, status) => {
	switch (model) {
		case 'order':
			switch (action) {
				case 'edit':
					// 只有待支付/已支付状态才能修改
					if (status == 'paying') return false;
					if (status == 'payed') return false;
				break;
				case 'send':
					// 只有已支付/待收货状态才能发货
					if (status == 'payed') return false;
					if (status == 'shipped') return false;
				break;
				case 'close':
					// 只有待支付状态才能关闭
					if (status == 'paying') return false;
				break;
				case 'delete':
					// 只有关闭状态才能删除
					if (status == 'closed') return false;
				break;
			}
		break;
	}
	return true;
}

// 过滤持有权限顶部菜单路由
const topRoute = (top_routes, routes) => {
	return top_routes.filter((t) => {
		if (t.children && t.children.length) {
			t.children = topRoute(t.children, routes);
			if (routes.has(t.fullPath) || t.children.length) return t;
		}
		if (routes.has(t.path)) return t;
	});
}

const isMobile = () => {
	const ua = window.navigator.userAgent.toLowerCase();
	if (ua.match(/mobile|iphone|android/i)) return true;
	return false;
}


const isiPad = () => {
	const ua = window.navigator;
	if (ua.platform === 'MacIntel' && ua.maxTouchPoints > 1) return true;
	return false;
}

const isWechat = () => {
	const ua = window.navigator.userAgent.toLowerCase();
	if (ua.match(/micromessenger/i) == 'micromessenger') return true;
	return false;
}

const isWochat = () => {
	const ua = window.navigator.userAgent.toLowerCase();
	if ((ua.match(/micromessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork')) return true;
	return false;
}

const base64toBlob = (base64Data, contentType) => {
	contentType = contentType || '';
	const sliceSize = 1024;
	const byteCharacters = atob(base64Data.split(',')[1]);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

const downloadBlob = (blob, filename) => {
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style = 'display: none';
	a.href = url;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL(url);
}



// 格式化出生日期
const formatDate = (date) => {
	const y = date.getFullYear();
	const m = String(date.getMonth() + 1).padStart(2, '0');
	const d = String(date.getDate()).padStart(2, '0');
	return `${y}-${m}-${d}`;
};

/**
 * 从中国身份证号码解析年龄和性别
 * @param {string} idCard 身份证号码（支持18位新号）
 * @returns {Object} { age: 年龄, gender: '男'/'女', birthDate: 'YYYY-MM-DD' } 或错误信息
 */
const parseIDCard = (idCard) => {
	// 基础验证
	if (typeof idCard !== 'string' || !/^\d{17}[\dXx]$/.test(idCard)) {
		return { error: '无效身份证号码' };
	}
	// 统一大写处理
	const id = idCard.toUpperCase();
	// 解析出生日期
	const year = parseInt(id.substr(6, 4));
	// JS月份从0开始
	const month = parseInt(id.substr(10, 2)) - 1;
	const day = parseInt(id.substr(12, 2));
	const birthDate = new Date(year, month, day);
	// 验证日期有效性
	if (birthDate.getFullYear() !== year || birthDate.getMonth() !== month || birthDate.getDate() !== day) {
		return { error: '无效出生日期' };
	}
	// 计算年龄
	const today = new Date();
	let age = today.getFullYear() - birthDate.getFullYear();
	const monthDiff = today.getMonth() - birthDate.getMonth();
	const dayDiff = today.getDate() - birthDate.getDate();
	// 未过生日则减1岁
	if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
		age--;
	}
	// 解析性别（第17位，索引16）
	const genderCode = parseInt(id.charAt(16));
	const gender = genderCode % 2 === 1 ? 1 : 2;
	return {
		age: age,
		gender: gender,
		birthDate: formatDate(birthDate)
	};
}

/**
 * 从身份证计算退休年份
 * @param {string} idCard - 身份证号码
 * @param {boolean} [isFemaleCadre=false] - 女性是否为干部
 * @returns {Object} { retirementYear: 退休年份, ... } 或错误信息
 */
const calcRetirementYear = (idCard, isFemaleCadre = false)  => {
	if (!/^\d{17}[\dXx]$/i.test(idCard)) return { error: "无效身份证号码" };
	const id = idCard.toUpperCase();
	// 解析出生日期
	const birthYear = parseInt(id.substr(6, 4));
	const birthMonth = parseInt(id.substr(10, 2)) - 1;
	const birthDay = parseInt(id.substr(12, 2));
	const birthDate = new Date(birthYear, birthMonth, birthDay);
	// 基础参数设置
	const policyStartDate = new Date('2025-01-01');
	// 解析性别
	const genderCode = parseInt(id.charAt(16));
	const isMale = genderCode % 2 === 1;
	// 原退休年龄判断
	let originalAge = 60;
	let targetAge = 63;
	let delayRate = 4;
	if (!isMale) {
		originalAge = isFemaleCadre ? 55 : 50;
		targetAge = isFemaleCadre ? 58 : 55;
		delayRate = isFemaleCadre ? 4 : 2;
	}

	// 计算原退休日期
	const originalRetirementDate = new Date(
		birthDate.getFullYear() + originalAge,
		birthDate.getMonth(),
		birthDate.getDate()
	);

	// 判断是否受延迟政策影响
	if (originalRetirementDate < policyStartDate) return { error: "不受延迟政策影响" };

	// 计算延迟月数
	const delayYears = (targetAge - originalAge) * 12;


	let delayMonths = Math.min(Math.ceil(((originalRetirementDate.getFullYear() - policyStartDate.getFullYear()) * 12 + birthDate.getMonth() + 1) / delayRate), delayYears);
	
	// 计算实际退休年龄（年+月）
	const delayYearsDecimal = delayMonths / 12;
	const actualRetireAge = originalAge + delayYearsDecimal;

	// 计算精确退休年份（考虑出生月份）
	const retireDate = new Date(birthDate);
	retireDate.setFullYear(birthYear + Math.floor(actualRetireAge));
	retireDate.setMonth(birthMonth + Math.round((actualRetireAge % 1) * 12));
	const month = delayMonths % 12;

	return {
		actualRetireAge: parseInt(actualRetireAge)+`岁${month}个月`,
		retirementYear: retireDate.getFullYear(),
		retirementMonth: retireDate.getMonth() + 1,
		originalAge,
		delayMonths
	};
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export default {
	checkPage,
	backPage,
	h5Copy,
	delay,
	isiPad,
	toRoute,
	topRoute,
	one2Many,
	isMobile,
	isWechat,
	isWochat,
	buildTree,
	checkAuth,
	parseIDCard,
	checkStatus,
	base64toBlob,
	downloadBlob,
	handleDownload,
	calcRetirementYear,
	index: (u) => checkAuth(u, 'default'),
	create: (u) => checkAuth(u, 'create'),
	delete: (u) => checkAuth(u, 'delete'),
	update: (u) => checkAuth(u, 'update'),
	restore: (u) => checkAuth(u, 'restore'),
	editOrder: (s) => {
		// 只有待支付/已支付状态才能修改
		if (s == 'paying') return false;
		if (s == 'payed') return false;
		return true;
	},
	sendOrder: (s) => {
		// 只有已支付/待收货状态才能发货
		if (s == 'payed') return false;
		if (s == 'shipped') return false;
		return true;
	},
	closeOrder: (s) => {
		// 只有待支付状态才能关闭
		if (s == 'paying') return false;
		return true;
	},
	deleteOrder: (s) => {
		// 只有关闭状态才能删除
		if (s == 'closed') return false;
		return true;
	},
	openWindow: (u) => {
		if (!u) return false;
		window.open(u);
	},
	uploadConfig: (type) => {
		var ext = ['png'], size = 1024, unit = 'B';
		const config = Store.state.config_uploads[type];
		if (config) {
			size = config.size;
			ext = config.ext.map(e => { return e.toLowerCase(); });
		}
		var format_size = size;
		if (format_size > 1024) {
			format_size = format_size/1024;
			unit = 'KB';
		}
		if (format_size > 1024) {
			format_size = format_size/1024;
			unit = 'MB';
		}
		if (format_size > 1024) {
			format_size = format_size/1024;
			unit = 'GB';
		}
		if (format_size > 1024) {
			format_size = format_size/1024;
			unit = 'TB';
		}
		return { type, size, ext, format_size, unit, format_size_unit: format_size + unit };
	},
	removeMenuTab (t, n) {
		if (t === '/') return false;
		var tabs = Store.state.tab_routes;
		tabs.forEach((tab, index) =>{
			if(tab.fullPath === t){
				let nextTab = tabs[index + 1] || tabs[index - 1];
				if (nextTab) {
					Store.commit('setTabRoutes', tabs.filter(tt => tt.fullPath !== t));
					toRoute(n ? n : nextTab.fullPath);
				}
			}
		});
	}
}